<template>
  <UikitFormSelect
    :model-value="modelValue"
    :class="{
      'osk-country-select': true,
      'osk-country-select_disabled': disabled,
    }"
    name="country-select"
    :placeholder="placeholder"
    :label="label"
    :disabled="disabled"
    hide-dropdown-icon
    @update:model-value="countryChanged"
  >
    <template #default>
      <UikitFormSelectOption
        v-for="item in countryOptions"
        :key="item.dialCode"
        :label="item.name"
        :value="item"
      >
        <div class="osk-country-select__option">
          <span :class="[ 'v3q_tel__flag', item.iso2.toLowerCase() ]">&nbsp;</span>
          <span class="osk-ml-sm">{{ item.name }}</span>
          <span class="osk-ml-sm">+{{ item.dialCode }}</span>
        </div>
      </UikitFormSelectOption>
    </template>

    <template #selected-item="{ item }">
      <div class="osk-country-select__selected">
        <span :class="['v3q_tel__flag q-mr-sm', (item?.iso2?.toLowerCase() ?? '')]">&nbsp;</span>

        <span class="osk-ml-xs osk-country-select__dropdown-arrow">
          ▼
        </span>

        <span
          v-if="item"
          class="osk-ml-sm"
        >
          +{{ item?.dialCode }}
        </span>
      </div>
    </template>
  </UikitFormSelect>
</template>

<script setup lang="ts">
import { ICountry, countries } from './countries';

const props = defineProps<{
  modelValue?: ICountry
  placeholder?: string
  label?: string
  disabled?: boolean
  onlyCountries?: string[]
}>();

const emits = defineEmits<{
  (e: 'update:modelValue', value: ICountry): void,
  (e: 'countryChanged', value: ICountry, prevCountry: ICountry): void,
}>();

let prevCountry: ICountry;

// const search = ref();
// const searchText = ref('');
const countryOptions = ref([...countries]);

// function performSearch() {
//   const needle = searchText.value.toLowerCase().trim();

//   countryOptions.value = needle === '' ? [...countries] : filterCountries(needle);
// }

// async function onShow() {
//   searchText.value = '';
//   performSearch();
//   await nextTick();
//   // search.value?.focus();
// }

function countryChanged(value: ICountry) {
  emits('update:modelValue', value);
  emits('countryChanged', value, prevCountry);

  prevCountry = value;
}

watch(() => props.onlyCountries, (items) => {
  if (!items) {
    countryOptions.value = [...countries];
    return;
  }

  countryOptions.value = countries.filter((c) => items.includes(c.iso2.toUpperCase()));
});

onMounted(() => {
  if (props.modelValue) {
    prevCountry = props.modelValue;
  }
});
</script>

<style lang="scss">
@import "~/assets/scss/settings/index";
@import '~/assets/scss/flags';

.osk-country-select {
  .osk-field__inner {
    border: 0;
  }

  .osk-dropdown__collapse {
    width: 360px;

    @include media-query(lg-and-up) {
      width: 407px;
    }
  }

  .osk-select__list {
    padding-top: 0;
  }

  .osk-field__inner,
  .osk-dropdown__activator {
    height: 100%;
  }

  &__option {
    padding: 4px 2px;
    display: flex;
    align-items: center;
  }

  &__selected {
    display: flex;
    align-items: center;
    height: 100%;
    padding: 0 2px 0 12px;
  }

  &__dropdown-arrow {
    transform: scaleY(.5);
    display: inline-block;
    color: #666;
    font-size: .8em;
    transition: all linear .2s;
  }

  &.osk-select_opened {
    .osk-country-select__dropdown-arrow {
      transform: scaleY(.5) rotate(180deg);
    }
  }

  &_disabled {
    pointer-events: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
}
</style>
