<template>
  <UikitFormInput
    ref="$input"
    :model-value="inpNumber"
    :name="name"
    :label="label"
    :placeholder="placeholder"
    :required="required"
    :rules="[isValidNumber]"
    :disabled="disabled"
    :border-bottom="borderBottom"
    :no-error-message="noErrorMessage"
    :error-text="errorText"
    :size="size"
    :loading="loading"
    :hide-star="hideStar"
    @update:model-value="numberChanged"
  >
    <template #prepend>
      <!-- :searchLabel="searchLabel"
        @countryChanged="countryChanged"
        v-model:country="country"
        v-bind="dropdownProps" -->

      <UikitFormPhoneCountrySelect
        v-model="country"
        :only-countries="onlyCountries"
        :disabled="disabled"
        @country-changed="countryChanged"
      />
    </template>
  </UikitFormInput>
</template>

<script setup lang="ts">
import { RuleExpression } from 'vee-validate';

import parsePhoneNumber, {
  AsYouType,
  CountryCode,
  PhoneNumber,
} from 'libphonenumber-js';

import { ICountry, getCountry, getCurrentCountryByIp } from './countries';

const props = defineProps<{
  modelValue?: string
  name: string
  label?: string
  disabled?: boolean
  placeholder?: string
  rules?: RuleExpression<string | number | number[] | boolean | null>
  required?: boolean
  borderBottom?: boolean
  noErrorMessage?: boolean
  errorText?: string,
  size?: 'm' | 'l'
  loading?: boolean
  onlyCountries?: string[]
  hideStar?: boolean
  defaultCountry?: string
}>();

const emits = defineEmits<{
  (e: 'update:modelValue', intNumber?: string): void,
  (e: 'update:valid', isValid: boolean | string): void;
  (e: 'phoneChanged', phone?: PhoneNumber): void;
  (e: 'countryChanged', country?: ICountry): void;
}>();

const { t } = useI18n();
const { isInternational } = useInternational();

const $input = ref();
const inpNumber = ref('');
const country = ref(props.modelValue ? undefined : getCountry(isInternational ? 'ae' : 'ru'));

const defaults = computed(() => {
  const cty = country.value;

  return {
    defaultCountry: cty?.iso2 as CountryCode,
    defaultCallingCode: cty?.dialCode,
  };
});

const phoneComp = computed(() => parsePhoneNumber(inpNumber.value, defaults.value));

function countryChanged() {
  numberChanged(inpNumber.value);
  emits('countryChanged', country.value);
}

function isValidNumber(val: string) {
  if (!val) {
    return props.required ? t('uikit.form.phone.phoneInput.required') : true;
  }

  const phone = parsePhoneNumber(val, defaults.value);

  if (!phone || !phone.isValid()) {
    return t('uikit.form.phone.phoneInput.invalid');
  }

  const phType = phone.getType();

  if (phType && ![ 'FIXED_LINE_OR_MOBILE', 'FIXED_LINE', 'MOBILE', 'VOIP' ].includes(phType)) {
    return 'Personal mobile or landline number required';
  }

  return true;
}

async function numberChanged(value: string | null, isStartedValue?: boolean) {
  // https://github.com/catamphetamine/libphonenumber-js#as-you-type-formatter
  let newNumber = value?.toString() || '';

  inpNumber.value = newNumber;

  await nextTick();

  const isStartedRussian = isStartedValue && newNumber.startsWith('+7');

  const countryCode = isStartedRussian ? 'RU' : country.value?.iso2 as CountryCode;
  const asYouType = new AsYouType(countryCode);

  // eslint-disable-next-line default-case
  switch (countryCode) {
    case 'AE':
      if (newNumber && !newNumber.startsWith('+971')) newNumber = `+971${newNumber}`;
      break;
    case 'AU':
      if (newNumber && /^[234578]$/.test(newNumber)) newNumber = `0${newNumber}`;
      break;
    case 'NZ':
      if (/^[234679]$/.test(newNumber)) newNumber = `0${newNumber}`;
      break;
    case 'RU':
      if (isStartedRussian) newNumber = newNumber.replace('+7', '');
  }

  newNumber = asYouType.input(newNumber);
  inpNumber.value = newNumber;

  // Change country only if a different country dialing prefix is entered
  // Multiple countries can use the same dialCode (e.g +1 => CA,US,...)
  const newCountryCode = asYouType.getCountry() as CountryCode;
  const newCountry = newCountryCode && getCountry(newCountryCode);

  if (newCountry && country.value?.dialCode !== newCountry?.dialCode) {
    country.value = newCountry;
  }

  if (inpNumber.value?.startsWith(`+${newCountry.dialCode}`)) {
    inpNumber.value = inpNumber.value.replace(`+${newCountry.dialCode}`, '');
  }

  const valid = isValidNumber(newNumber);
  const newPhone = asYouType.getNumber();

  if (valid === true) {
    emits('phoneChanged', newPhone);
    emits('update:valid', true);
  } else {
    emits('phoneChanged', undefined);
    emits('update:valid', valid);
  }

  emits('update:modelValue', newPhone?.number);
}

function setFocus() {
  if ($input.value) {
    $input.value.setFocus();
  }
}

onMounted(() => {
  if (props.modelValue) {
    numberChanged(props.modelValue, true);
  } else {
    getCurrentCountryByIp().then((res) => {
      if (res && props.onlyCountries?.includes(res) && country.value?.iso2?.toUpperCase() !== res) {
        country.value = getCountry(res);
      }
    }).catch((error) => {
      console.error(error);
    });
  }
});

defineExpose({
  phoneComp,
  setFocus,
});
</script>
